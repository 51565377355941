import './ui-elements'
import './form'
import './popup'
import './animation'

$(function () {



  Fancybox.bind("[data-fancybox]", {});
  console.log(Fancybox);

  if($("div").is(".js-banner-slider")){ 
    var product = new Swiper('.js-banner-slider', {
      spaceBetween: 0,		
      slidesPerView: 1,
      loop: true,
      pagination: { 
        el: '.js-banner-pagination',
        // type: 'bullets',
      },
    });
  }

  if($("div").is(".js-reels-slider")){ 

    var product = new Swiper('.js-reels-slider', {
      spaceBetween: 20,		
      slidesPerView: 2,
      loop: true,      
      breakpoints: {
        600: {
          slidesPerView: 1,
          spaceBetween: 0, 
        },
      }, 
      pagination: { 
        el: '.js-reels-pagination',
        clickable: true, 
      },
      navigation: {
        nextEl: '.js-reels-next', 
        prevEl: '.js-reels-prev',
      },  

    }); 
  }

  if($("div").is(".js-quize-slider")){ 
    var product = new Swiper('.js-quize-slider', {
      spaceBetween: 0,		
      slidesPerView: 1,
      loop: false,
      allowTouchMove: false,
      autoHeight: true, 
      pagination: {
        el: '.js-quize-pagination',
        type: 'fraction',  
      },
      on: {
        slideChange: function () {
          if (this.realIndex === this.slides.length - 1) {
            $('.quize-ask__finish').removeClass("d-none");
            $('.quize-ask__nav').hide();
            $('.quize-btn--next').hide();
            $('.person').addClass("d-none");
          } else { 
            $('.quize-ask__finish').addClass("d-none");
            $('.quize-ask__nav').show(); 
            $('.quize-btn--next').show();
            $('.person').removeClass("d-none");
          }
        },
      }
    });

    $(".quize-btn--prev").on('click',function(e){	
      e.preventDefault();
      product.slidePrev();
    });  

    $(".quize-btn--next").on('click',function(e){	
      e.preventDefault(); 
      product.slideNext();
    });

  }


  $('.js-nav-btn').on('click',function(){	
    var $this = $(this);
    var navBlock = $('.js-menu-wrap');
    $this.toggleClass('burger_active');
    if ($this.hasClass('burger_active')) {
        navBlock.animate({"left":"0px"});	
      } else {
        navBlock.animate({"left":"-100%"});	
      } 

  });


  if($('.home').length) {
    $(".js-anchor a").click(function (e) {
      e.preventDefault();
      if($(window).width()<=992){
        var navBlock = $('.js-menu-wrap');
        navBlock.animate({"left":"-100%"});	
        $('.js-nav-btn').removeClass('burger_active');
      }
      
    
      var _href = $(this).attr("href").split('#').pop();	
      console.log(_href);
      $("html, body").animate({
        scrollTop: ($("#"+_href).offset().top-50) + "px"
      });
      return false;
    });
  } 

  $(window).scroll(function(){
    var aTop = 0;
    var navblock= $('header'); 
    if($(this).scrollTop()>aTop){
      navblock.addClass('fixed');
    } else{
      navblock.removeClass('fixed');
    }
  });

  // $(".home .js-anchor a").click(function () {

  //   var _href = $(this).attr("href").split('#').pop();	

  //   $("html, body").animate({ 
  //     scrollTop: ($(_href).offset().top-50) + "px"
  //   });

  //   var navBlock = $('.js-menu-wrap');
 
  //   navBlock.animate({"left":"-100%"});	

  //   $('.burger').removeClass("burger_active"); 
    
  //   return false; 
  // });
 
 
  $("a.js-anchor").click(function () {
    var _href = $(this).attr("href");
    $("html, body").animate({
      scrollTop: ($(_href).offset().top-50) + "px"
    });
    return false;
  });

  $('.js-portfolio-btn').on("click",function(e){

    e.preventDefault();

    $('.js-portfolio-btn').removeClass("active");

    $(this).addClass("active");

    let data={
      'term_id': $(this).data('term_id'), 
      'action': 'portfolio_filter',
      'count': $(this).data('count'),
    };
    console.log(data); 

    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: data, 
      error: function (jqXHR, exception) {
      },
      beforeSend : function ( xhr ) {		
      
      },
      success : function( data ){   
        console.log(data);
        if (data) {
          jQuery('.portfolio-items').html(data);        
        } else { }
      }
    });

  });


  // function sliders_init() {
  //   $('.js-project-slider').each(function(){
  //     var doneslider = new Swiper( this.querySelector('.js-project-slider'), {
  //       spaceBetween: 10,
  //       autoHeight: true,
  //       // navigation: {
  //       //   nextEl: this.querySelector('.psm_next'),
  //       //   prevEl: this.querySelector('.swiper-pagination'),
  //       // },
  //       pagination: {
  //         el: this.querySelector('.swiper-pagination'),
  //       },
  //            loop: true,
  //           loopedSlides: 4
  //       });
  //   });
  // }

  // sliders_init();
  

  $('.faq-item__header').click(function(event){			  
    event.preventDefault();   
    $(this).parent().toggleClass("opened");
  }); 


  $('.service-item').click(function(event){			  
    event.preventDefault();   
    $('.service-item').removeClass("opened");
    $(this).addClass("opened"); 

    let index=$(this).data("index"); 
    $('.service-text').removeClass("active");
     
    $(".service-text[data-text="+index+"]").addClass("active"); 


    // $("html, body").animate({
    //   scrollTop: ($("#servicetext").offset().top) + "px"
    // });  
    
  });


    
  if (($(".product-slider").length > 0 )) {

    var doneslider = new Swiper( this.querySelector('.main-slider'), {
      spaceBetween: 0,
      autoHeight: true,
      navigation: {
        nextEl: this.querySelector('.psm_next'),
        prevEl: this.querySelector('.psm_prev'),
      },
          loop: true,
          loopedSlides: 4
      });

      $('.js-slide-to').click(function(e) {
        e.preventDefault();
        let index=$(this).data("slide");
        console.log(index);
        doneslider.slideTo(index, 300);
      });

  }

    
  // if (($(".js-project-slider").length > 0 )) {

  //   var doneslider = new Swiper( this.querySelector('.js-project-slider'), {
  //     spaceBetween: 0,
  //     autoHeight: true,
  //     navigation: {
  //       nextEl: this.querySelector('.psm_next'),
  //       prevEl: this.querySelector('.psm_prev'),
  //     },
  //         loop: true,
  //         loopedSlides: 4
  //     });

  //     $('.js-slide-to').click(function(e) {
  //       e.preventDefault();
  //       let index=$(this).data("slide");
  //       console.log(index);
  //       doneslider.slideTo(index, 300);
  //     });

  // }



  $('.js-product-cat').click(function(e) {

    e.preventDefault(); 
    $('.js-product-cat').removeClass("active");
    $(this).addClass("active");
    let term_id= $(this).data('term_id'); 
    let blog_id= $(this).data('blog_id'); 
    let lang= $(this).data('lang'); 

    let count= $(this).data('count'); 
    let data={
      'term_id': term_id, 
      'blog_id' : blog_id, 
      'lang': lang,
      'action': 'car_filter',
      'count': count,
    };

    console.log(data);

    if(term_id!=0)  $('.pag').addClass("d-none"); else $('.pag').removeClass("d-none");
   
    
    $.ajax({
      type: 'POST',
      url: '/wp-admin/admin-ajax.php',
      data: data, 
      error: function (jqXHR, exception) {
      },
      beforeSend : function ( xhr ) {		
      
      },
      success : function( data ){   
        console.log(data);
        if (data) {
          jQuery('.js-product-items').html(data);
          sliders_init();
        
        } else { }
      }
    });

  });
  

  // const start = datepicker('.start', { id: 1 })
  // const end = datepicker('.end', { id: 1 })


  // rent form
  // $('.js-rent-form').submit(function(e) {
  //   e.preventDefault();
  //   let thisForm = $(this);
  //   let formData = new FormData($(this)[0]);


  //   $.ajax({
  //     url: '/wp-admin/admin-ajax.php',
  //     type: 'POST',
  //     processData: false,
  //     contentType: false,
  //     data: formData, 
  //     dataType: "json",
  //     success: function(data) {
  //       console.log(data);
  //       openPopup("thank", "", "","","");
  //       setTimeout(function(){
  //         $('.thank-popup').removeClass('is-show');
  //       },4000); 
  //     }  
  //   });

  // });

  $('.js-back').click(function(e){
    e.preventDefault();
    history.back();
  });




  $('.js-all-options').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });

  $('.js-view-all').click(function(e){
    e.preventDefault();
    $(this).toggleClass("opened"); 
    $(this).closest('.product-card').find('.product-info__row.hidden').slideToggle();
  });
  if($(".js-range-slider").length) { 
    $(".js-range-slider").ionRangeSlider({
      type: "single", 
      min: 0,
      max: 500, 
      from: 0,  
      onChange: function(data) {    
        $('input[name=area]').val(data.from);
        $('.js-value-val').text(data.from);
      }
    });
  }


  var plascroll = new Swiper(".plan-swiper", {
    direction: "horizontal",
    slidesPerView: "auto",
    freeMode: true,
    // loop: true, 
    scrollbar: {
      el: ".swiper-scrollbar",
    },
    mousewheel: true,
  });

  // if($("div").is(".js-project-slider")){ 
    
  //   var product = new Swiper('.js-project-slider', {
  //     spaceBetween: 20,		
  //     slidesPerView: 3,
  //     direction: "vertical",  
  //     // freeMode: true,
  //     // loop: true,
      
  //     // breakpoints: {
  //     //   500: {
  //     //     slidesPerView: 3,
  //     //     spaceBetween: 0,
  //     //   },
  //     // },
  //     // pagination: { 
  //     //   el: '.js-reels-pagination',
  //     // }
  //   });
  // }

  var project = new Swiper(".js-project-slider", {
    direction: 'vertical',
    slidesPerView: 'auto',
    freeMode: true,
    scrollbar: {
      el: '.swiper-scrollbar',
    },
    mousewheel: true,
  }); 


  $('.js-full-text').click(function (e) {
    e.preventDefault();
    $(this).closest('.project-content').find(".text-hidden").toggleClass("show");
    console.log($(this).text()); 
    ($(this).text() == 'Подробнее') ? $(this).text("Скрыть") : $(this).text("Подробнее"); 

  });

  
}); 