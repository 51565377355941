$(function () {

	// Ввод только цифр
		// $('.js-number').keypress(function(e){
		// 	let charCode = (e.which) ? e.which : e.keyCode;
		// 	if (charCode > 31 && (charCode < 48 || charCode > 57)) {
		// 		return false;
		// 	}
		// });
	
	// Деактивация кнопки отправки формы при снятии галочки с чекбокса согласия
		// $('body').on('change', '.js-agree', function () {
		// 	let $this = $(this);
		// 	let btn = $this.closest('.js-form').find('.submit');
		// 	if ($this.is(':checked')) {
		// 		btn.removeAttr("disabled"); 
		// 	} else {
		// 		btn.attr("disabled", true);
		// 	}
		// });
	
	
	// Валидация формы
		let formValidate = function() {
	
			let form = $('.js-form');
			
			form.each(function(){
				let thisForm = $(this);

				let tgname=$(this).data("tgname");

				thisForm.validate({
					messages: {
						name: thisForm.find('input[name=name]').data("errorname"), 
						phone: thisForm.find('input[name=phone]').data("errorname"),
					},
					focusInvalid: false,
					highlight: function(element) {
						$(element).closest(".form-field").addClass('form-field--error');
						thisForm.find('button').prop("disabled", true);
					},
					unhighlight: function(element) { 
						$(element).closest(".form-field").removeClass('form-field--error');
						thisForm.find('button').prop("disabled", false);
					},
					submitHandler: function(form, event){		
						event.preventDefault();			
						
						
						let formData = new FormData(thisForm[0]);

				

						$.ajax({
							url: '/wp-admin/admin-ajax.php',
							type: 'POST',
							processData: false,
							contentType: false,
							data: formData, 
							dataType: "json",
							success: function(data) {						

								let success_form=thisForm.data("thank");
								console.log(success_form);
								openPopup(success_form, "", "","","");
								
								// console.log(tgname);
								// ym(98715654,'reachGoal',tgname);
							
								setTimeout(function(){
									
									const $elem = $('.js-popup[data-block="'+success_form+'"]');
									$elem.removeClass('is-show');

								},4000); 
							}  
						});


							
	
						return false;
					}
				});
	
			});
		};
	
		formValidate();
	
		window.formValidate = formValidate;
	
		$B.on('click', 'label.error',function(){
			$(this).hide().siblings('input,textarea').focus();
	
		});
	
		$B.on('keyup', 'input',function(){
			$(this).siblings('label.error').hide();
		});
	
		$B.on('change', '.js-select', function(){
			console.log('rfd')
			$(this).closest('.form-field').removeClass('form-field--error');
		})
	
		$('.popup-thank').on('click', function(){
			$(this).removeClass('is-show');
		});
	
		
	
	});
	
	
	